@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1440px;
  margin: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-login-black{
  background-color: #1e1e1e;
}

.bg-monstrade-blue {
  background-color: #0052FF;
}

.monstrade-blue-color {
  color: #0052FF;
}

.bg-monstrade-button-green {
  background-color: #84bd31;
}

.bg-monstrade-button-green:hover {
  background-color: #6a942a;
}

.bg-monstrade-button-red {
  background-color: #df2e2e;
}

.bg-monstrade-button-red:hover {
  background-color: #9c1e1e;
}

.banner-gradient {
  background-color: #020202;
  background-image: linear-gradient(#020202, #001F4F);
}

.silver-gradient{
  background-color: #a3a3a3;
  background-image: linear-gradient(#a3a3a3,#707070);
}

.gold-gradient{
  background-color: #c9ab00;
  background-image: linear-gradient(#c99a00,#e6e352,#f7f583);
}

.platinium-gradient{
  background-color: #333333;
  background-image: linear-gradient(#333333,#585858,#a8a8a8);
}

.fa-caret-down {
  margin: auto !important;
}

.fa-caret-up {
  margin: auto !important;
}

.triangle-bottom {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #0052FF transparent transparent transparent;
}

.container-max {
  width: 80vw;
  max-width: 1080px;
}

.container-full {
  width: 100vw;
  max-width: 1440px;
}

ul {
  list-style-position: inside;
}

.collapse {
  visibility: visible;
}

@media only screen and (max-width: 768px) {
  .container-max {
    width: 90vw;
    max-width: 1080px;
  }
}

@media only screen and (min-width: 769px) {
  .container-max {
    width: 80vw;
    max-width: 1080px;
  }
}