.clockContainer{
    position: relative;
    width: 70px;
    height: 70px;
    margin: auto;
    /* background-color: rgb(156 156 156 / var(--tw-bg-opacity)); */

}

.circleContainer{
    width: 70px;
    height: 70px;
    position:relative;
    z-index:1;
}
  
  .quarterTopLeft {
    position: relative;
    width: 35px;
    height: 35px;
    border-top-left-radius: 70px;
    background-color: rgb(156 156 156);
    float: left;
    animation: anm 0.2s linear;
    animation-delay: 2s;
    animation-fill-mode: both;
  }
  
  .quarterTopRight {
    width: 35px;
    height: 35px;
    border-top-right-radius: 70px;
    float: right;
    animation: rtclck 2.7s linear;
    animation-delay: 0;
    background-color: rgb(238 238 238);
    /* background-color: red; */
    transform-origin: bottom left;
    transform-style: preserve-3D;
    z-index: 999999;

  }
  
  .quarterBottomLeft {
    position: relative;
    z-index: -1;
    width: 35px;
    height: 35px;
    border-bottom-left-radius: 70px;
    background-color: rgb(156 156 156);
    float: left;
    animation: anm 0.2s linear;
    animation-delay: 1.4s;
    animation-fill-mode: both;
  }
  
  .quarterBottomRight {
    position: relative;
    z-index: -1;
    width: 35px;
    height: 35px;
    border-bottom-right-radius: 70px;
    background-color: rgb(156 156 156);
    float: right;
    animation: anm 0.2s linear;
    animation-delay: 0.8s;
    animation-fill-mode: both;
  }

.hourTick{
    position: absolute;
    border-radius: 9999px;
    width: 4px;
    height: 25px;
    padding-top: 6px;
    padding-bottom: 6px;
    z-index: 70;
    animation: anm 1s linear;
    animation-fill-mode: both;
}

.hourInner{
    background-color: rgb(156 156 156);
    width: 100%;
    height: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
}



.hourTick:nth-child(1){
    top: -35%;
    left: 48%;
    rotate: 0deg;
    animation-delay: 0.2s;
}


.hourTick:nth-child(2){
    top: -18px;
    left: calc( 100% - 12px);
    rotate: 30deg;
    animation-delay: 0.4s;
}


.hourTick:nth-child(3){
    top: 0px;
    left: calc(125% - 12px);
    rotate: 60deg;
    animation-delay: 0.6s;
}

.hourTick:nth-child(4){
    top: 24px;
    left: calc(100% + 12px);
    rotate: 90deg;
    animation-delay: 0.8s;
}

.hourTick:nth-child(5){
    top: 66%;
    left: calc(110% - 2px);
    rotate: 120deg;
    animation-delay: 1s;
}

.hourTick:nth-child(6){
    top: 92%;
    left: 82%;
    rotate: 150deg;
    animation-delay: 1.2s;
}

.hourTick:nth-child(7){
    top: 100%;
    left: 48%;
    rotate: 180deg;
    animation-delay: 1.4s;
}

.hourTick:nth-child(8){
    top: 92%;
    left: 14%;
    rotate: 210deg;
    animation-delay: 1.6s;
}

.hourTick:nth-child(9){
    top: 66%;
    left: calc(-10% - 2px);
    rotate: 240deg;
    animation-delay: 1.8s;
}

.hourTick:nth-child(10){
    top: calc(50% - 12.5px);
    left: calc(0% - 12.5px);
    rotate: 270deg;
    animation-delay: 2s;
}

.hourTick:nth-child(11){
    top: 0px;
    left: calc(10% - 12px);
    rotate: 300deg;
    animation-delay: 2.2s;
}

.hourTick:nth-child(12){
    top: -18px;
    left: calc(32% - 12px);
    rotate: 330deg;
    animation-delay: 2.4s;
}

@keyframes anm {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes rtclck {
    from {rotate: 0deg; }
    to {rotate: 360deg; }
}   

