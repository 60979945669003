.fullGr {
    background: rgb(0, 82, 255);
    background: linear-gradient(180deg, rgba(0, 82, 255, 1) 0%, rgba(49, 151, 208, 1) 66%, rgba(0, 199, 255, 1) 100%);
}

.longGr{
    background: rgb(0, 82, 255);
    background: linear-gradient(180deg, rgba(0, 82, 255, 1) 0%, rgba(49, 151, 208, 1) 100%);
}

.shortGr{
    background: rgb(0, 82, 255);
    background: linear-gradient(180deg, rgba(49, 151, 208, 1) 0%, rgba(0, 199, 255, 1) 100%);
}