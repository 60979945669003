/* Arrow */
.arrowContainer {
    rotate: 295deg;
    position: absolute;
    right: -50px;
    bottom: -100px;
}

.arrow {
    position: relative;
    margin: 0 auto;
    width: 50px;
}

.arrow .curve {
    border: 3px solid #0000;
    border-color: transparent transparent transparent #000000;
    height: 180px;
    width: 600px;
    border-radius: 115px 0 0 75px;
}

.arrow .point {
    position: absolute;
    left: 20px;
    top: 157.5px;
}

.arrow .point:before,
.arrow .point:after {
    border: 1px solid #030100;
    height: 12.5px;
    content: "";
    position: absolute;
}

.arrow .point:before {
    top: -5.5px;
    left: -5.5px;
    transform: rotate(-70deg);
    -webkit-transform: rotate(-70deg);
    -moz-transform: rotate(-70deg);
    -ms-transform: rotate(-70deg);
}

.arrow .point:after {
    top: -10px;
    left: 2.5px;
    transform: rotate(18deg);
    -webkit-transform: rotate(18deg);
    -moz-transform: rotate(18deg);
    -ms-transform: rotate(18deg);
}