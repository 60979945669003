.colapseContainer{
    height: 65px;
    width: 75%;
    background: linear-gradient(90deg, #D9D9D9 0%, #0052FF 0.01%, #00C7FF 115.31%);
    border-radius: 10px;
    margin: 20px 0px 0px auto;
    position: relative;
}

.tabStyle{
    width: 100%;
    display: flex;
    justify-content: center;
    height: 0;
    transition: height 1s;
}

.activeTab{
    width: 100%;
    display: flex;
    justify-content: center;
    transition: height 1s;
    height: 300px !important;
}

.tableStyle{
    table-layout: fixed;
    visibility: hidden;
    opacity: 0;
    transition: visibility 1s linear 1.1s;
    transition: opacity 1s linear 1s;
}

.activeTable{
    width: 75%;
    opacity: 1;
    visibility: visible;
    transition: visibility 1.1s linear;
    transition: opacity 1.1s linear;
}

