.tag-list {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    gap: 0.3rem 0;
    position: relative;
    padding: 1.5rem 0;
    overflow: hidden;
  }

  .slider-inner {
    display: flex;
    width: fit-content;
    animation-name: loop;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: var(--direction);
    animation-duration: var(--duration);
    height: 100%;
  }

  .loop-slider{
    max-height: 118px;
  }

  .fade {
    pointer-events: none;
    background: linear-gradient(
      90deg,
      #1e293b,
      transparent 30%,
      transparent 70%,
      #1e293b
    );
    position: absolute;
    inset: 0;
  }
  
  @keyframes loop {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-40%);
    }
  }